$(function() {
	var APP = window.APP || {};

	var $form = $('.contactForm');

	// do we need to get our CSRF token info?
	if( $form.length && APP.enableCsrfProtection && ( ! APP.csrfTokenValue || ! APP.csrfTokenName ) )
	{
		APP.getCsrfToken();
	}

	var xhr;

	var formOldHtml = $form.html();
	var successDestroyed = false;

	var formStates = {

		// form states - could be considered for internal use
		disableForm: function() {
			$form.addClass("js-disabled").find("input, button, textarea, select").attr("disabled", true);
		},

		enableForm: function() {
			$form.removeClass("js-disabled").find("input, button, textarea, select").attr("disabled", false);
		},


		// 1. disable the form, present a loading spinner while we wait for the form to submit
		loading: function (argument) {
			$form.addClass("js-loading");
			// this.disableForm();
		},

		// 2. hide form, load thank you message.  this is destructive, and requires a page refresh to reset the form.
		success: function (argument) {
			successDestroyed = true;
			$form
				.removeClass("js-loading")
				.addClass("js-complete")
				.height( $form.height() ) // set it so that we don't change size.
				.html('<div class="success-message">Thank you for your message. We will be in touch soon.</div>');


			TRACKING.send({
					label: "contactform",
					category: "contactform",
					action: "message sent",
					value: 0
				});
		},

		// 3. get us back to an original state, perhaps if they close the overlay?
		reset: function() {

			// Cancel an ajax request?
			// POST.CANCEL(); etc

			// remove loading states
			$form.removeClass("js-complete").removeClass("js-loading");

			if (successDestroyed) {
				successDestroyed = false;
				$form.html( formOldHtml ).height( 'auto' );
			}

			// renable form
			// this.enableForm();

		}
	};



	$form.validate({
		rules: {
			"fromName": "required",
			"fromEmail": {
				required: true,
				email: true
			}
		},
		messages: {
			fromName: "This field cannot be left blank",
			email: {
				required: "We need your email address to contact you",
				email: "Your email address must be in the format of name@domain.com"
			}
		},
		submitHandler: function(form) {

			// set our state to loading
			formStates.loading();

			// Get the post data
			var data = $(form).serializeArray();

			// Since our textarea can't be blank on the server, give it some dummy content if it's blank
			var $textarea = $('[name="message[body]"]');
			if (!$textarea.val()) {
				data.push({
					name: 'message[body]',
					value: 'The message was left blank.'
				});
			}

			// add CSRF if we need to and have it.
			// if for some reason there isn't a token name & value set,
			// then this form will fail. It's an edge case, it would mean
			// a legitimate user was able to fill in & submit the form quicker than
			// we were able to grab the token from the server.
			if(APP.enableCsrfProtection && APP.csrfTokenName && APP.csrfTokenValue)
			{
				data.push({
					name: APP.csrfTokenName,
					value: APP.csrfTokenValue
				});
			}

			// and submit
			xhr = $.ajax({
				type: "POST",
				url: $(form).attr('action'),
				data: data,
				success: function(response) {

					if (response.success) {
						formStates.success();
					} else {
						// response.error will be an object containing any validation errors that occurred, indexed by field name
						// e.g. response.error.fromName => ['From Name is required']
						alert('An error occurred. Please try again.');
						console.log(response.error);
						formStates.reset();
					}
				},
				error: function(response) {
					console.log(response);
					console.log("error submitting form");
					alert('An error occurred. Please try again.');
					formStates.reset();
				}
			});
		}
	});




	// expose what you need...
	APP.formStates = formStates;

});
