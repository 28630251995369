$(document).ready(function() {

	/**
	 *
	 * This script assumes there will only ever be one .vr element
	 * on the page.  It could be refactored easy enough to change
	 * the selectors below to be a case of 'find' on each $carousel
	 * element, but this is slightly more heavy lifting than
	 * the assumption of one, since our design just doesn't have
	 * two!
	 *
	 */

	var $carousel = $('.vr')
		,$prev = $('.vr__prevNext .icomoonBtn--caret-left')
		,$next = $('.vr__prevNext .icomoonBtn--caret-right')
		,$textContainer = $('.vr__textInner')
		, activeSlide = 0
		;

	if ($carousel.length === 0) return;

	var slideCount = $carousel.find('.vr__slide').length;

	var getYoutubeId = function (url) {
		var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
		var match = url.match(regExp);

		if (match && match[2].length == 11) {
			return match[2];
		} else {
			return 'error';
		}
	};

	var createIframe = function(url, el) {
		var $iframe = $('<iframe src="//www.youtube.com/embed/' +  getYoutubeId(url) + '?rel=0" frameborder="0" allowfullscreen></iframe>');
		$iframe.appendTo( el );
	};

	var next = function(e) {
		e.preventDefault();
		activateSlide( ( activeSlide + 1 === slideCount ) ? 0 : activeSlide + 1 );
	};

	var prev = function(e) {
		e.preventDefault();
		activateSlide( ( activeSlide - 1 === -1 ) ? 0 : activeSlide - 1 );


	}

	var activateSlide = function( index ) {
		activeSlide = index;
		$('.vr__slide').removeClass('vr__slide--active');

		var $activeSlide = $('.vr__slide[data-index="' + activeSlide + '"]');
		$activeSlide.addClass('vr__slide--active');

		var text = $activeSlide.find('.vr__caption').html();

		$textContainer.fadeOut(function() {
			$textContainer.html( text ).fadeIn();

		})

		if (!$activeSlide.hasClass('vr__slide--iframeready')) {
			var url = $activeSlide.attr('data-url');
			createIframe( url, $activeSlide );
			$activeSlide.addClass('vr__slide--iframeready');
		}
	}


	$prev.on('click', prev);
	$next.on('click', next);

});
