
/* global $ */
/* eslint-disable */

var toggleAttr = function toggleAttr(el, attr) {
	var state = el.getAttribute(attr);
	var newState = state === "true" ? "false" : "true";
	el.setAttribute(attr, newState);
	return newState;
};

var closeAll = function closeAll( exceptLink, exceptLi ) {
	$('#nav .nav-a, #nav .nav-down').each(function (i, link) {
		if ( link !== exceptLink ) {
			link.setAttribute('aria-expanded', 'false');
		}
	});

	$('#nav .nav-li').each(function (i, li) {
		if ( li !== exceptLi ) {
			li.setAttribute('data-state','closed');
		}
	});
};

var timer = void 0;

$(function () {

	

	$('#nav .nav-li').each(function (i, li) {
		
	

		// let lock = false;

		// if ( i === 2 ) {
		// 	lock = true;
		// }

		var link = li.querySelector('.nav-a');
		var btn = li.querySelector('.nav-down');

		if ( !btn && link.tagName === "BUTTON" ) { btn = link; }
		// var shelf = li.querySelector('.nav-reveal');

		// Data State set by mouse, 
		// do not set aria labels, as that 
		// would confuse the state for a kb / blind
		// user....
		

		var openShelf = function (e) {
			
			closeAll( link, li );
			li.setAttribute('data-state', 'open');
			clearTimeout(timer);
		};

		li.addEventListener('mouseover', openShelf);
	
		var closeShelf = function (e) {

			clearTimeout(timer);
			timer = setTimeout(function () {
				
				closeAll();
			}, 400);
		};

		li.addEventListener('mouseout', closeShelf);
		
		// if ( !lock ) {
		// } else {
		// 	openShelf();
		// 	return;
		// }


		if ( btn ) {
			// For keyboard users, install the 'down' button, 
			// so that this can toggle the menu as a separate
			// focusable to the main item, which is a link.

			// Toggle the menu on a click of this button...
			// Note that since hover is handled separately,
			// then clicking while hovered will not appear to
			// do anything as the hover will override.
			btn.addEventListener('click', function (e) {

				var state = link.getAttribute('data-state');
				var expanded = link.getAttribute('aria-expanded');

				// Close everything else...
				closeAll( link, li );

				if (state === "open" || expanded === "true") {
					link.setAttribute('aria-expanded', 'false');
					if ( btn ) { btn.setAttribute('aria-expanded', 'false'); }
					li.setAttribute('data-state','closed');
				} else {
					li.setAttribute('data-state','open');
					if ( btn ) { btn.setAttribute('aria-expanded', 'true'); }
					link.setAttribute('aria-expanded', 'true');
				}
				e.preventDefault();
			});
		}

		
	});
});


$( function()  {

	$('.video-container').each( function(i, container ) {

		var vid;
		var getVid = function() {
			
			
			if ( !vid ) {
				vid = container.querySelector('video');
				
				if ( vid ) {
					// first time getting....
					vid.addEventListener('canplay', test );					
				}
			}

			return vid;

		}

		var test = function() {
			// Might have appeared....
			var vid = getVid();
			if (! vid ) { return; }

			var w = window.innerWidth;
			var h = window.innerHeight;

			var vw = vid.videoWidth;
			var vh = vid.videoHeight;
			var ratio = vh / vw;

			var windowRatio = h / w;
			// container.style.top = `0`;
			// container.style.left = `0`;
			if (windowRatio > ratio) {
				vid.style.width = 'auto';
				vid.style.height = '100%';
			} else {
				vid.style.height = 'auto';
				vid.style.width = '100%';
			}
		};

		
		window.addEventListener('resize', test);
		test();
	});

});