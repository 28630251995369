;(function() {

    var APP = window.APP || {};

	// as soon as the DOM is finished
	$(function() {

		$(".fancybox--iframe").fancybox({
			maxWidth	: 1066,
			maxHeight	: 600,
			fitToView	: false,
			width		: '95%',
			height		: '95%',
			autoSize	: false,
			closeClick	: false,
			openEffect	: 'fade',
			closeEffect	: 'fade',
			'transitionIn'  : 'fade',
        	'transitionOut' : 'fade',
			padding: 0
		});

		$('.fancybox--iframe').on('click', function() {

			TRACKING.send({
				label: "homepage video",
				category: "videos",
				action: "pressed play",
				value: 0
			});
		});

		$('#js-social').dcSocialStream({
			feeds: {
				twitter: {
					id: 'nektonmission',
					images : 'small', // exclude images with empty string; otherwise one of: thumb, small, medium, large
					url : '/twitter.php',
					retweets : false,
					replies: false,
					out: 'text,date,intro'
				},
				instagram : {
					id: '!3267924457',
					// Create at https://www.instagram.com/developer/
					clientId: '563e37f6b44c4bf687acb83779d1e3b5',
					// Generated via http://jelled.com/instagram/access-token
					// Alternate option: http://instagram.pixelunion.net/ and generating a new token
					accessToken: '3267924457.563e37f.4992490dd8104261a2af8d664dfd2144'
					// out: 'thumb,intro,date'
				},
				youtube: {
					id: 'NektonMission/UUMrYQWm0FZm6vXI1iS99cWA',
					thumb : 'medium',
					api_key: 'AIzaSyBgaJslr9A8luqBF3m3gmnvit4KDTslhuQ',
					out: 'thumb,title,intro',
				},
				facebook: {
					// found by entering https://www.facebook.com/nektonmission into http://findmyfbid.com
					id: '138259229921974',
					image_width : 4,
					comments: 0,
					out: 'thumb,text,intro',
					feed: 'posts',
					url: '/facebook.php'
				}
			},
			rotate: {
				delay: 0
			},
			control: false, // hide links for controlling feed rotator
			filter: false, // hide filter navigation allowing the user to filter specific social networks
			wall: true, // output the social stream as an isotope powered social network wall
			cache: true, // cache AJAX response
			external: true, // open all links in new browser window
			max: 'limit', // create the feed based on number of results per network
			limit: $('#js-social').data('limit') || 10, // Maximum number of results to check/return for each social network
			iconPath: '/assets/vendor/codecanyon/jquery-social-stream/images/dcsns-light/'
		});
	});

	// once EVERYTHING has been downloaded
	$(window).load(function() {

		// Load our myfonts css
		// It's fine to load this, we don't want to lie about page count
		// but we also don't want their page counter to block our rendering
		// if it goes down.
		$('head').append('<style>@import url("//hello.myfonts.net/count/317e8c");@import url("//hello.myfonts.net/count/31695d");</style>');
	});
})();
