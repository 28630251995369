$(document).ready(function() {

	var $carousel = $('.home__threeUp .threeUp');
	if ($carousel.length === 0) return;

	var options = {
		wrapAround: true,
		autoPlay: false,
		cellSelector: '.threeUp__one',
		setGallerySize: true,
		pageDots: true,
		prevNextButtons: false,
		arrowShape: false,
	}

	var carouselActive = false;
	var setupCarousel = function() {

		var isMobile = ($(window).width() <= 480);

		if ( carouselActive && !isMobile) {
			$carousel.flickity('destroy');
			carouselActive = false;

		} else if ( !carouselActive && isMobile ) {

			$carousel.flickity( options );
			carouselActive = true;
		}
	};

	setupCarousel();
	$(window).on('resizeEnd', setupCarousel );



});
