var TRACKING = (function() {

		// scope some defaults that cannot be changed by other scripts.
		var originalDefaults = {
			category: "general",
			value: false,
			nonInteraction: false
		}

		return {



			debug: false, // will turn console logging on or off, people can turn it on in the console if they wish.


			defaults: originalDefaults,

			// a user may set temporary defaults like so:
			/**
			 *      TRACKING.defaults.category = "Video";
			 *
			 *      TRACKING.send( action, label ); // sends to "Video" category
			 *
			 *      TRACKING.resetDefaults();
			 *
			 *      TRACKING.send( action, label ); // sends to "Homepage Map" Category
			 */
			resetDefaults: function() {
				this.defaults = originalDefaults;
			},

			log: function( ) {
				// send only if the debugging is turned on
				if (this.debug) {
					for (x = 0; x < arguments.length; x++) {

						console.log( arguments[x] );
					};
				}
			},

			// the rationale for putting category later is because this is something
			// that often stays the same, so makes for easier coding.
			//
			// We can even set the default temporarily and reset - see above
			send: function( eventobj ) {


				var ga = window.ga || false;


				if (typeof eventobj === "object") {

					if (!eventobj.label) { this.log("no label assigned - not sending event"); return false; }
					if (!eventobj.action) { this.log("no action assigned - not sending event"); return false; }

					// we can't have no label or action, we can't guess at something like that.
					eventobj.category = eventobj.category || this.defaults.category;
					eventobj.nonInteraction = eventobj.nonInteraction || this.defaults.nonInteraction;
					eventobj.value = eventobj.value || this.defaults.value;

				} else {
					this.log("incorrect data sent");
					return false;
				}



				this.log( "Attempting to Send Event", eventobj );


				if (typeof ga === 'function') {

					ga('send','event', eventobj.category, eventobj.action, eventobj.label, eventobj.value, {
					  nonInteraction: eventobj.nonInteraction
					});

				} else {
					// do not use conditional log function, if this is happening something broke and we should
					// always notify in the console.
					console.warn( "No GA function present, events not sent" );
				}

			},

			// quickly make a jquery event listener, use body on for bubbling.
			// Ensure that stoppropagation wasn't used on anything!
			on: function( eventLabel, selector, data) {
				var self = this;

				$('body').on( eventLabel, selector, function(e) {

					var eventObject;

					if (typeof data === "function") {
						eventObject = data.apply(this, [e]);
					} else {
						eventObject = data;
					}

					self.send( eventObject );
				});

			}

		};


	})();




