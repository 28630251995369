$(document).ready(function() {

	var initFlexImages = function() {
		var rowHeight;

		if ($(window).width() <= 480) {
			rowHeight = 150;
		} else {
			rowHeight = 400;
		}


		$('.pageBlock--gallery .gallery').flexImages({
			rowHeight: rowHeight,
			truncate: true
		});

	};


	initFlexImages();
	// allow our heights to change based on size.
	$(window).on('resizeEnd', initFlexImages);



	$('.pageBlock--gallery .gallery').each( function() {
		this.gallery = new Photoswiper( this );
	});


});
