/* eslint-disable */

$(function () {



	var makeScroller = function makeScroller(el) {
		if (!el) {
			return false;
		}

		var targetQuery = el.getAttribute('data-target');
		var target = el;
		if (targetQuery) {
			target = document.querySelector(targetQuery);
		}

		var prop = el.getAttribute('data-scroll');
		var from = parseFloat(el.getAttribute('data-from'), 10);
		var to = parseFloat(el.getAttribute('data-to'), 10);

		var rect = el.getBoundingClientRect();

		return function () {
			var p = Math.max(0, 
					Math.min(rect.height, 
						window.pageYOffset - rect.top)
					);
						
			
			p = p / (rect.bottom - rect.top);

			var amount = (p * (to - from)) + from;
			target.style[prop] = amount;

			
		};
	};

	var bg = document.querySelector('#scroller1');
	var paintBackground = makeScroller(bg);

	
	// var bg2 = document.querySelector('#scroller2');
	// var paintBackground2 = makeScroller(bg2);

	var fns = [];

	$('[data-fader]').each( function( i, fader ) {
		fns.push( makeScroller( fader ) );
	});
	

	

	var paint = function paint() {
		fns.forEach( function( fn ) {  fn(); } );
	};

	/* SCROLL EVENTS */
	var scrollTimer = void 0;
	var scrolling = false;

	paint();

	var loop = function loop() {
		// console.log("loop");
		if (scrolling) {
			paint();
		}
		requestAnimationFrame(loop);
	};

	loop();

	document.addEventListener('scroll', function (e) {
		clearTimeout(scrollTimer);
		scrolling = true;
		scrollTimer = setTimeout(function () {
			scrolling = false;
		}, 500);
	});
});