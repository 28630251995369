$(document).ready(function() {

	// debug
	// Draggable.create(".draggable", {type:"x,y", bounds:"#draggableOuter"});

	// create resize end event.
	(function() {
		var resizeTimer;

		$(window).on('resize', function(e) {

		  clearTimeout(resizeTimer);

		  resizeTimer = setTimeout(function() {
			// This can sometimes fire twice in what appears to be the
			// same resizing movements - eg if people pause mid resize
			// so anything you bind to this - make sure you add some
			// code to stop a repeating firing.
			$(window).trigger('resizeEnd');

		  }, 250);

		});
	})();

});
